<template>
    <div class="admin-settings-trnaslations">
        <b-tabs pills content-class="mt-3" v-model="activeTab">
            <b-tab title="Pending Review">
                <GoodDataTable 
                    ref="dataTable1" 
                    class="w-100" 
                    mode="local" 
                    :no-serial="true" 
                    :columns="columns1"
                    :load-local-data="true"
                />
            </b-tab>
            <b-tab title="Approved">
                <GoodDataTable 
                    ref="dataTable2" 
                    class="w-100" 
                    mode="local" 
                    :no-serial="true" 
                    :columns="columns2"
                    :load-local-data="true" 
                />
            </b-tab>
            <b-tab title="Confirmed">
                <GoodDataTable 
                    ref="dataTable3" 
                    class="w-100" 
                    mode="local" 
                    :no-serial="true" 
                    :columns="columns3"
                    :load-local-data="true" 
                />
            </b-tab>
            <b-tab title="Completed">
                <GoodDataTable 
                    ref="dataTable4" 
                    class="w-100" 
                    mode="local" 
                    :no-serial="true" 
                    :columns="columns4"
                    :load-local-data="true" 
                />
            </b-tab>
        </b-tabs>
        <b-modal v-model="detailModel" hide-footer size="lg">
            <div class="row d-flex justify-content-center">
                <div class="col-6 mb-3">
                    <b-form @submit.prevent="submitForm">
                        <div class="row">
                            <div v-if="!isEmpty(curReq)" class="col-12 mb-1">
                                <ul class="ml-0 pl-0" style="list-style: none">
                                    <li>Requestor: <strong>{{ curReq.requestor.name }} ({{ curReq.requestor.type }})</strong></li>
                                    <li>Requested Amount: <strong>${{ curReq.requested_amount }}</strong></li>
                                    <li>Requested On: <strong>{{ dateTimeFormat(curReq.created_at, 'DD MMM, YYYY') }}</strong></li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <b-form-group label="Status">
                                    <b-form-select v-model="form.status" :options="reqStatusOptions" />
                                </b-form-group>
                            </div>
                            <div v-if="form.status >= 3" class="col-12">
                                <b-form-group label="Payment Medium">
                                    <b-form-select v-model="form.payment_medium" :options="paymentMediumOptions" />
                                </b-form-group>
                            </div>
                            <div v-if="form.status >= 3" class="col-12">
                                <b-form-group label="Transaction Id">
                                    <b-form-input v-model="form.transaction_id" placeholder="Transaction Id" />
                                </b-form-group>
                            </div>
                            <div v-if="form.status >= 3" class="col-12">
                                <b-form-group label="Transaction Date">
                                    <b-form-datepicker v-model="form.transaction_date" />
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group label="Note">
                                    <b-form-textarea
                                        v-model="form.note"
                                        placeholder="Enter note..."
                                        rows="3"
                                        max-rows="5"
                                    />
                                </b-form-group>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <b-button type="submit" variant="outline-success" block>Save</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <GoodDataTable 
                        ref="dataTableDetails" 
                        class="w-100" 
                        mode="local" 
                        :no-serial="true" 
                        :columns="columnsDetails"
                        :load-local-data="true" 
                    />
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoodDataTable from "@/@core/layouts/shared/GoodDataTable.vue";
import { dateTimeFormat, isEmpty } from '@/utilities';
import { hideLoader, showLoader } from "@/@core/comp-functions/ui/app";

const columns = [
    {
        field: 'id',
        label: 'Request Id',
        sortable: false,
    },
    {
        field: 'requested_amount',
        label: 'Amount',
        useResolver: true,
        useHtml: true,
        renderer: props => `$${props.requested_amount}`,
    },
    {
        field: 'created_at',
        label: 'Date',
        useResolver: true,
        useHtml: true,
        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
    },
    {
        field: 'user_type',
        label: 'User Type',
        useResolver: true,
        useHtml: true,
        renderer: props => isEmpty(props.requestor) ? 'N/A' : `${props.requestor.type}`,
    },
    {
        field: 'user',
        label: 'User',
        useResolver: true,
        useHtml: true,
        renderer: props => isEmpty(props.requestor) ? 'N/A' : `${props.requestor.name}`,
    },
]

const reqStatusOptions = [
    { value: 1, text: 'Pending' },
    { value: 2, text: 'Approved' },
    { value: 3, text: 'Payment Processing' },
    { value: 4, text: 'Completed' },
]

const paymentMediumOptions = [
    { value: 'stripe', text: 'Stripe' },
    { value: 'razorpay', text: 'Razorpay' },
    { value: 'paypal', text: 'Paypal' },
    { value: 'paytm', text: 'Paytm' },
    { value: 'iyzico', text: 'Iyzico' },
    { value: 'paystack', text: 'Paystack' },
    { value: 'flutterwave', text: 'Flutterwave' },
    { value: 'duitku', text: 'Duitku' },
    { value: 'yookassa', text: 'Yookassa' },
    { value: 'molile', text: 'Molile' },
    { value: 'mercadopago', text: 'Mercadopago' },
    { value: 'midtrans', text: 'Midtrans' },
]

export default {
    name: "AdminAffilationRequests",
    components: {
        GoodDataTable
    },
    data: () => ({
        activeTab: 0,
        detailModel: false,
        reqStatusOptions,
        paymentMediumOptions,
        form: {
            id: null,
            status: null,
            payment_medium: null,
            transaction_date: null,
            transaction_id: null,
            note: null,
        },
        curReq: {}
    }),
    computed: {
        ...mapGetters('affilation', [
            'payout_requests'
        ]),
        pending: {
            get(){
                return this.payout_requests.filter(i => i.status == 1)
            }
        },
        approved: {
            get(){
                return this.payout_requests.filter(i => i.status == 2)
            }
        },
        confirmed: {
            get(){
                return this.payout_requests.filter(i => i.status == 3)
            }
        },
        completed: {
            get(){
                return this.payout_requests.filter(i => i.status == 4)
            }
        },
        columns1: {
            get() {
                let _columns = []
                _columns = _columns.concat(columns)
                _columns.push({
                    label: 'Action',
                    field: 'action',
                    sortable: false,
                    type: 'dropdown',
                    buttons: [
                        {
                            icon: 'View Details',
                            title: 'View Details',
                            image: this.$helpers.getIcons('view'),
                            action: props => {
                                this.openModal(props)
                            },
                        },
                    ],
                })
                return _columns
            }
        },
        columns2: {
            get() {
                let _columns = []
                _columns = _columns.concat(columns)
                _columns.push({
                    label: 'Action',
                    field: 'action',
                    sortable: false,
                    type: 'dropdown',
                    buttons: [
                        {
                            icon: 'View Details',
                            title: 'View Details',
                            image: this.$helpers.getIcons('view'),
                            action: props => {
                                this.openModal(props)
                            },
                        },
                    ],
                })
                return _columns
            }
        },
        columns3: {
            get() {
                let _columns = []
                _columns = _columns.concat(columns)
                _columns.push({
                    label: 'Action',
                    field: 'action',
                    sortable: false,
                    type: 'dropdown',
                    buttons: [
                        {
                            icon: 'View Details',
                            title: 'View Details',
                            image: this.$helpers.getIcons('view'),
                            action: props => {
                                this.openModal(props)
                            },
                        },
                    ],
                })
                return _columns
            }
        },
        columns4: {
            get() {
                let _columns = []
                _columns = _columns.concat(columns)
                _columns.push({
                    label: 'Action',
                    field: 'action',
                    sortable: false,
                    type: 'dropdown',
                    buttons: [
                        {
                            icon: 'View Details',
                            title: 'View Details',
                            image: this.$helpers.getIcons('view'),
                            action: props => {
                                this.openModal(props)
                            },
                        },
                    ],
                })
                return _columns
            }
        },
        columnsDetails: {
            get(){
                return [
                    {
                        field: 'commission_rate',
                        label: 'Commission Percentage',
                        sortable: false,
                    },
                    {
                        field: 'amount',
                        label: 'Amount',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => `$${props.amount}`,
                    },
                    {
                        field: 'created_at',
                        label: 'Date',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.created_at) ? 'N/A' : dateTimeFormat(props.created_at, 'DD MMM, YYYY'),
                    },
                    {
                        field: 'referral_type',
                        label: 'Referral Type',
                    },
                    {
                        field: 'referral',
                        label: 'Referral',
                    },
                ]
            }
        }
    },
    methods: {
        isEmpty, dateTimeFormat,
        ...mapActions('affilation', [
            'getPayoutRequests',
            'getPayoutRequestSubItems',
            'updatePayoutRequest',
        ]),
        setData(){
            if(this.$refs.dataTable1){
                this.$refs.dataTable1.setLocalData(this.pending)
            }
            if(this.$refs.dataTable2){
                this.$refs.dataTable2.setLocalData(this.approved)
            }
            if(this.$refs.dataTable3){
                this.$refs.dataTable3.setLocalData(this.confirmed)
            }
            if(this.$refs.dataTable4){
                this.$refs.dataTable4.setLocalData(this.completed)
            }
        },
        async submitForm(){
            showLoader()
            await this.updatePayoutRequest(this.form)
            hideLoader()
            this.curReq = {}
            this.detailModel = false
        },
        updateForm(req){
            this.resetForm()
            this.form.id = req.id
            this.form.status = req.status
            if(req.status > 2){
                this.form.payment_medium = req.payment_medium;
                this.form.transaction_date = req.transaction_date;
                this.form.transaction_id = req.transaction_id;
            }
            this.form.note = req.note
        },
        async openModal(req) {
            this.resetForm()
            showLoader()
            this.detailModel = true
            this.getPayoutRequestSubItems(req.id).then(r => {
                if(r._statusCode === 200){
                    this.curReq = req
                    this.$refs.dataTableDetails.setLocalData(r.responseData)
                    this.updateForm(req)
                }
                hideLoader()
            }).catch(e => {
                hideLoader()
            })
        },
        resetForm(){
            this.form = {
                id: null,
                status: null,
                payment_medium: null,
                transaction_date: null,
                transaction_id: null,
                note: null,
            }
        }
    },
    watch: {
        payout_requests: {
            handler(v){
                this.setData()
            },
            deep: true,
            immediate: true
        },
        activeTab: {
            handler(v){
                this.setData()
            },
            deep: true,
            immediate: true
        },
    },
    mounted(){
        this.getPayoutRequests()
        this.setData()
    }
}
</script>